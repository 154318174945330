import wx from 'weixin-js-sdk'
import { getJssdkConfig } from '@/api/collect'

export default {
    data () {
        return {
            playerHeight: 0
        }
    },
    created () {
        this.$eventBus.$on('playerReady', () => {
            this.playerHeight = document.getElementById('xg').offsetHeight
        }) 

        const isProd = process.env.NODE_ENV === 'production'
        if (!isProd) {
            // return
        }
        getJssdkConfig({
            url: window.authUrl
        }).then(res => {
            this.jssdkConfig = res.data
            wx.config(res.data)
        })
    },
    computed: {
        actualHeight () {
            if (this.playerHeight <= 0) {
                return
            }
            const actualHeight = document.documentElement.clientHeight - this.playerHeight
            if (actualHeight > 0) {
                return actualHeight + 'px'
            }
        }
    },
    methods: {
        registerWechatShare (video) {
            let desc = ''
            if (video.description) {
                desc = video.description
            } else {
                if (video.content) {
                    desc = video.content.replace(/<[^>]+>/g, '').substring(0, 50).trim()
                }
                if (!desc) {
                    desc = (video.category ? video.category.name : '') + ' / ' + video.author
                }
            }
            // let tail = description
            // if (video && video.channel) {
            //     tail = video.channel.name
            // }

            const shareData = {
                title: video.title,
                desc: desc,
                link: window.location.href.split('#')[0],
                imgUrl: video.thumb + '/wxshare'
            }
            wx.ready(() => {
                wx.updateTimelineShareData(shareData)
                wx.updateAppMessageShareData(shareData)
            })
        }
    }
}
