import {axios} from '@/utils/request'

export function isCollected(parameter) {
    return axios({
        url: 'collects/is',
        method: 'get',
        params: parameter
    })
}

export function collected(parameter) {
    return axios({
        url: 'collects',
        method: 'post',
        data: parameter
    })
}

export function removeCollect(parameter) {
    return axios({
        url: 'collects',
        method: 'delete',
        params: parameter
    })
}

export function getJssdkConfig(parameter) {
    return axios({
        url: 'commons/jssdk',
        method: 'get',
        params: parameter
    })
}

